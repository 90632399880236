import React from "react";

const AboutContent = () => {

    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_categories.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container">
                        <div className="header-icons"><span></span><span></span><span></span></div>
                        <div className="page-head__title">La société malienne des équipements - SME</div>
                        <div className="page-head__breadcrumb">
                            <ul className="uk-breadcrumb">
                                <li><a href="#">Home</a></li>
                                <li><span>SME</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content">
            <div className="uk-section-large uk-container">
                <div className="uk-grid" data-uk-grid="true">
                    <div className="uk-width-3-3@m">
                        <article className="article-full">

                            <h3>Présentation</h3>
                            <p>SME Sarl est une société malienne qui à pour objet en République du Mali et à l'Etranger:</p>

                            <ul className="list list-mark-1">
                                <li>Vente et Location des équipements d'occasions ou neufs;</li>
                                <li>Vente des machines e t d'engins;</li>
                                <li>Approvisionnement du marché local en besoin de matériels ou équipements.</li>
                            </ul>
                            <p>Et généralement ,toutes opérations industrielles, commerciales,
                                mobilières ,immobilières et financières pouvant se rattacher directement aux activités sus indiquées ou concourir à leur développement.</p>


                            <ul className="list list-mark-1">
                                <li><strong>RCCM:</strong> MA.BKO.2022.B.12662</li>
                                <li><strong>NINA:</strong> 42209194223581D</li>
                            </ul>

                            <p>Nous travaillons avec des partenaires sûrs tout en donnant des services de qualité pour la satisfaction de nos clients à travers le Monde.</p>

                            <span>Nos champs d'activités</span>:

                            <ul className="list list-mark-1">
                                <li><strong>Mali</strong></li>
                                <li><strong>Burkina Faso</strong></li>
                                <li><strong>Guinée Conakry</strong></li>
                            </ul>

                            <p>Nous vous assurons une satisfaction complète pour le choix de vos projets.</p>

                            <h3>Notre mission</h3>

                            <ul className="list list-mark-1">
                                <li>Assister nos partenaires à définir leur stratégie de développement;</li>
                                <li>Fournir des équipements adéquats et efficaces répondant aux besoins de nos
                                    Clients;</li>
                                <li>Négocier et gérer les contrats de nos partenaires d'affaires;</li>
                                <li>Donner à la fois une satisfaction au Client et à son Partenaire;</li>
                                <li>Faire du Business Autrement; Etc.</li>
                            </ul>


                            <h3>Nos offres</h3>

                            <ul className="list list-mark-1">
                                <li>Louer ou acheter les meilleurs engins au meilleur prix du marché;</li>
                                <li>Vendre les engins de nos partenaires;</li>
                                <li>Nous vous offrons des engins de meilleure qualité pour vos projets aux meilleurs prix.</li>
                                <li>A travers notre grande expertise, nous vous conseillerons sur les meilleurs choix d'engins de chantiers sur le marché en terme de maintenance facile et
                                    plus efficace pour votre projet.</li>
                                <li>Nous vous proposons des engins parfaitement entretenus.</li>
                                <li>Nous sommes à votre entière disposition pour tous vos besoins d'engins.</li>
                                <li>Vous vous faites des soucis à propos des coûts? Exprimez simplement votre besoin, nous nous occupons du reste.</li>
                            </ul>

                            <h5>Rejoignez-nous sur vos reseaux sociaux préférés</h5>

                            <ul className="social-list">
                                <li className="social-list__item"><a className="social-list__link" target="_blank" href="https://www.facebook.com/societemalienne/"><i
                                    className="fab fa-facebook-f"></i></a></li>
                                {/*<li className="social-list__item"><a className="social-list__link" target="_blank"  href="#!"><i
                                    className="fab fa-twitter"></i></a></li>*/}

                                <li className="social-list__item"><a className="social-list__link" target="_blank" href="https://www.linkedin.com/company/smequipe/"><i
                                    className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </article>

                    </div>


                </div>
            </div>
        </div>
    </>
}

export default AboutContent;
