import React, {useEffect} from "react";
import Layout from "../components/layout/Layout";
import AboutContent from "../components/AboutContent";
import {loadScript} from "../util/helper";
const About = () => {
    useEffect(() => {
        loadScript("/assets/js/libs.js");
        setTimeout(() => {
            loadScript("/assets/js/main.js");
        })
    })
    return <>
        <Layout title={'A propos de nous'}>
            <AboutContent/>
        </Layout>
    </>
}
export default About;